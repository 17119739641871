<template>
  <div class="about-cookx">
    <!-- Head Start-->
    <div
      class="about-cookx__section-wrapper about-cookx__section-head-wrapper secondary--text"
      :style="`
        background-image: url('${backgroundImageUrl(1)}');
        min-height: ${bg1MinHeight}px;`"
    >
      <PageLayout
        :pageTitle="$t('label.aboutUs')"
        :pageSubtitle="$t('label.aFoodieSharingCommunity')"
        pageTitleDividerClass="secondary"
        color="secondary"
        layoutType="c"
        CustomCss='font-size: 2.5vw !important; font-family: "Caveat"!important;'
        :hideLogo="true"
      ></PageLayout>

      <!-- Section First Start -->
      <div
        v-intersect="{
          handler: onIntersectFirstSection,
          options: {
            threshold: 1.0
          }
        }"
        class="pt-6 pt-sm-12 pt-md-0"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          class="content-wrapper"
        >
          <v-col cols="12" md="7" lg="9" xl="10" class="text-center">
            <!-- About us Image -->
            <div style="position: relative">
              <transition name="bounce">
                <div v-show="isShowAboutUsImg">
                  <img
                    class="max-w-100 aboutUsSrc"
                    :src="aboutUsSrc"
                    alt="about"
                  />
                  <div class="bottom-left text-right" v-if="!isMobile">
                    <hr class="phone-title-line" />
                    <div class="phone-title-bg-image">
                      <span
                        class="font_Grape"
                        v-html="$t('aboutUs.app_section[0].title')"
                      ></span>
                      <hr />
                    </div>
                    <p
                      class="mt-4"
                      v-html="$t('aboutUs.app_section[0].description')"
                    ></p>
                  </div>
                  <div class="bottom-right text-right" v-if="!isMobile">
                    <hr class="phone-title-line" />
                    <div class="phone-title-bg-image">
                      <span
                        class="font_Grape phone-title-bg-image"
                        v-html="$t('aboutUs.app_section[1].title')"
                      ></span>
                    </div>
                    <p
                      class="mt-4"
                      v-html="$t('aboutUs.app_section[1].description')"
                    ></p>
                  </div>
                </div>
              </transition>
            </div>
            <div class="m-bottom-left text-left" v-if="isMobile">
              <div class="phone-title-bg-image">
                <span
                  class="font_Grape"
                  v-html="$t('aboutUs.app_section[0].title')"
                ></span>
                <hr />
              </div>
              <p v-html="$t('aboutUs.app_section[0].description')"></p>
            </div>

            <div class="m-bottom-left text-left" v-if="isMobile">
              <div class="phone-title-bg-image">
                <span
                  class="font_Grape"
                  v-html="$t('aboutUs.app_section[1].title')"
                ></span>
                <hr />
              </div>
              <p v-html="$t('aboutUs.app_section[1].description')"></p>
            </div>
            <!-- About us Image -->

            <!-- About us Video -->
            <transition name="slide-fade-y">
              <div v-show="isShowAboutUsImg" class="video_title">
                <div class="justify-start align-center mb-7">
                  <p class="title-bg-image font_Grape secondary--text">
                    Eco-Pack
                  </p>
                  <p>Pre-order Journey</p>
                </div>
                <video
                  ref="video"
                  muted
                  autoplay
                  loop
                  playsinline
                  controls
                  disablePictureInPicture
                  style="width: 100%; border-radius: 20px"
                >
                  <source
                    src="https://cookx.oss-ap-southeast-3.aliyuncs.com/web/EcoPack2.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </transition>
            <!-- About us Video -->
            <!-- About us 2x -->
            <transition name="slide-fade-y">
              <div v-show="isShowAboutUsImg" class="about_intro">
                <p class="font_Grape text-left title-bg-image mb-7">
                  Switch Profile
                </p>
                <v-row
                  style="justify-content: space-between"
                  no-gutters
                  align="center"
                  :style="switchProfile"
                >
                  <div class="Creator">
                    <p class="font_Grape">Creator-Partner</p>
                    <img
                      src="/assets/image/about-us/desktop/about-us_creator.png"
                      alt="creator"
                    />
                    <p>CONNECT & COLLABORATE</p>
                    <p>SHARE & SELL</p>
                  </div>
                  <div class="switch">
                    <img
                      class=""
                      src="/assets/image/about-us/desktop/Switch Option1.png"
                      alt=""
                    />
                  </div>
                  <div class="User">
                    <p class="font_Grape">User</p>
                    <img
                      src="/assets/image/about-us/desktop/about-us_user.png"
                      alt="user"
                    />
                    <p>LEARN & EXPLORE</p>
                    <p>PRE-ORDER & REFER</p>
                  </div>
                </v-row>
              </div>
            </transition>
            <!-- About us 2x -->
            <!-- About us Description -->
            <v-row
              no-gutters
              justify="start"
              justify-sm="start"
              class="pt-6 pt-md-0"
            >
              <v-col cols="12" lg="9">
                <transition name="slide-fade-x">
                  <p
                    v-show="isShowAboutUsImg"
                    style="line-height: 1.6"
                    class="text-start text-caption text-description text-sm-body-2 text-md-body-1 text-lg-h5 secondary--text font-weight-regular enter-delay-1000ms"
                    v-html="$t('aboutUs.description')"
                  ></p>
                </transition>
              </v-col>
            </v-row>
            <!-- About us Description -->
          </v-col>
        </v-row>
      </div>
      <!-- Section First End -->
    </div>
    <!-- Head End-->
  </div>
</template>

<script>
  import PageLayout from '@/views/layout/PageLayout';
  import { Configuration } from '@/core/utils';

  export default {
    name: 'AboutCookX',
    components: {
      PageLayout
    },
    data: () => ({
      assetEnvironment: Configuration.value('assetEnvironment'),
      spacesUrl: Configuration.value('spacesUrl'),
      isShowAboutUsImg: false,
      isShowSecondSection: false,
      isShowThirdSection: false,
      Experience: [
        {
          bubble: 'bubble',
          title: 'Cook Smart',
          text: 'Learn from cooking video and pre-order what you need.'
          // img: '/assets/image/about-selling/Eco-Pack.png'
        },
        {
          bubble: 'bubble1',
          title: 'Cook Eco',
          text: 'Get Pre-measured Ingredients Eco pack by recipe video to keep your fridge clutter-free and fresh'
          // img: '/assets/image/about-selling/Eco-Pack.png'
        }
      ]
    }),
    computed: {
      currentScreenWidth() {
        return window.screen.width;
      },
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      logoMaxWidth() {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 160;
        } else if (this.$vuetify.breakpoint.smOnly) {
          return 120;
        } else {
          return 90;
        }
      },
      aboutUsSrc() {
        // let device = this.isMobile ? 'mobile' : 'desktop';

        return `/assets/image/about-us/desktop/about_us_mobile_app.png`;
      },
      chillNCookCommunity() {
        return [
          {
            src: `${this.spacesUrl}/${this.assetEnvironment}/assets/about-us_cook-smart.gif`
          },
          {
            src: '/assets/image/about-us_cook-eco.png'
          }
        ];
      },
      gettyEatCommunity() {
        return [
          {
            src: '/assets/image/about-us_get-smart.png'
          },
          {
            src: `${this.spacesUrl}/${this.assetEnvironment}/assets/about-us_get-to-eat.gif`
          }
        ];
      },
      switchProfile() {
        let css = this.isMobile ? 'flex-direction: column;' : '';
        return css;
      },
      bg1MinHeight() {
        const DEFAULT_DESKTOP_MIN_HEIGHT = 1508;
        const DEFAULT_MOBILE_MIN_HEIGHT = 696;

        return this.getBackgroundImageMinHeight(
          DEFAULT_DESKTOP_MIN_HEIGHT,
          DEFAULT_MOBILE_MIN_HEIGHT
        );
      },
      bg2MinHeight() {
        const DEFAULT_DESKTOP_MIN_HEIGHT = 1006;
        const DEFAULT_MOBILE_MIN_HEIGHT = 481;

        return this.getBackgroundImageMinHeight(
          DEFAULT_DESKTOP_MIN_HEIGHT,
          DEFAULT_MOBILE_MIN_HEIGHT
        );
      },
      bg3MinHeight() {
        const DEFAULT_DESKTOP_MIN_HEIGHT = 1047;
        const DEFAULT_MOBILE_MIN_HEIGHT = 533;

        return this.getBackgroundImageMinHeight(
          DEFAULT_DESKTOP_MIN_HEIGHT,
          DEFAULT_MOBILE_MIN_HEIGHT
        );
      }
    },
    methods: {
      onIntersectThirdSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowThirdSection = true;
      },
      onIntersectSecondSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowSecondSection = true;
      },

      onIntersectFirstSection(entries, observer, isIntersecting) {
        if (isIntersecting) this.isShowAboutUsImg = true;
      },
      backgroundImageUrl(value) {
        return `/assets/image/about-us/${
          this.isMobile ? 'mobile' : 'desktop'
        }/about-us_background-${value}.jpg`;
      },
      getBackgroundImageMinHeight(desktopMinHeight, mobileMinHeight) {
        const BACKGROUND_IMAGE_DESKTOP_WIDTH = 1508;
        const BACKGROUND_IMAGE_MOBILE_WIDTH = 428;
        const HEIGHT_ADJUSTMENT = 108;

        let isMobile = this.isMobile;
        let height = isMobile ? mobileMinHeight : desktopMinHeight;
        let currentScreenWidth = this.$vuetify.breakpoint.width;

        if (
          currentScreenWidth >
          (isMobile
            ? BACKGROUND_IMAGE_MOBILE_WIDTH
            : BACKGROUND_IMAGE_DESKTOP_WIDTH)
        ) {
          height =
            (isMobile
              ? mobileMinHeight + HEIGHT_ADJUSTMENT
              : desktopMinHeight) +
            (currentScreenWidth -
              (isMobile
                ? BACKGROUND_IMAGE_MOBILE_WIDTH
                : BACKGROUND_IMAGE_DESKTOP_WIDTH));
        }

        return height;
      },
      displayPostDate() {
        return (this.bubble = false);
      }
    }
  };
</script>

<style lang="scss">
  .font_Grape {
    font-family: 'Caveat';
  }

  .about-cookx {
    .about-cookx__section-wrapper {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  @media (max-width: 959px) {
    .about-cookx__section-head-wrapper {
      background-position: right center !important;
    }

    .about-cookx__section-chillNCook-wrapper,
    .about-cookx__section-gettyEat-wrapper {
      img {
        max-width: 90% !important;
      }
    }
  }

  .bottom-left {
    position: absolute;
    top: 50%;
    left: -20%;
    width: 350px;
  }

  .bottom-right {
    position: absolute;
    top: 35%;
    right: -30%;
    width: 350px;
  }

  @media (max-width: 1280px) {
    .bottom-right {
      right: -25%;
      width: 230px;
    }

    .bottom-left {
      left: -20%;
      width: 230px;
    }

    .phone-title-bg-image {
      width: 280px;
      margin: auto;
    }
  }

  @media (min-width: 1550px) {
    .bottom-left {
      left: -20%;
      width: 350px;
    }

    .bottom-right {
      right: -25%;
      width: 350px;
    }
  }

  @media (min-width: 1920px) {
    .bottom-left {
      left: -10%;
      width: 420px;
    }

    .bottom-right {
      right: -15%;
      width: 420px;
    }
  }

  .bottom-left span:nth-child(1),
  .bottom-right span:nth-child(1) {
    font-size: 2.5vw;
    font-weight: bold;
  }

  .bottom-right p:nth-child(1) {
    position: relative;
    right: 50px;
  }

  .bottom-left p,
  .bottom-right p {
    font-size: 1.2vw;
    // margin-bottom: unset;
  }

  .bottom-left button,
  .bottom-right button {
    font-size: 2.2vw;
    // margin-left: 10px;
    padding: 10px 0px 0px 24px;
  }

  .video_title {
    padding: 4vw 0;
  }

  .video_title p:nth-of-type(1) {
    font-size: 2.5vw;
    padding: 0 2.5vw;
  }

  .video_title p:nth-of-type(2) {
    font-size: 1.3vw;
    width: 700px;
  }

  :is(.Creator, .User) p:nth-of-type(1) {
    font-size: 3vw;
  }

  :is(.Creator, .User) p:nth-of-type(2),
  :is(.Creator, .User) p:nth-of-type(3) {
    font-size: 1.5vw;
    font-weight: bold;
  }

  :is(.Creator, .User) img {
    width: 20vw;
  }

  .switch img {
    width: 10vw;
    animation: rotate 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(180deg);
    }
  }

  .text-description {
    padding: 5vw 2vw;
  }

  .speech-bubble {
    position: absolute;
    background: #e7db47;
    border-radius: 0.4em;
    padding: 2vw;
    width: 300px;
    font-size: 1.1vw;
    margin-left: -75px;
    font-family: sans-serif;

    p {
      margin-bottom: unset;
    }

    // display: none;
  }

  .speech-bubble:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 0.531em solid transparent;
    border-bottom-color: #e7db47;
    border-top: 0;
    margin-left: -0.531em;
    margin-top: -0.531em;
  }

  .speech-bubble .closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
  }

  @media screen and (max-width: 920px) {
    // .aboutUsSrc{
    //   width: 70%;
    // }
    .m-bottom-right {
      margin-top: 25px;
    }

    .m-bottom-left span:nth-child(1),
    .m-bottom-right span:nth-child(1) {
      font-size: 6vw;
      // margin-bottom: 10px;
    }

    .m-bottom-left p:nth-child(2),
    .m-bottom-right p:nth-child(2) {
      font-size: 0.7rem;
      margin: 20px 0px !important;
    }

    .m-bottom-left button,
    .m-bottom-right button {
      padding: 1px;
      font-size: 6vw;
      margin-left: 10px;
      // margin-bottom: 10px;
    }

    .speech-bubble {
      margin-left: -10px;
      width: 75px;
      font-size: 2vw;
    }

    .speech-bubble .closebtn {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 12px;
    }

    .video_title div {
      // margin: 40px 0px 0px;
    }

    .video_title p:nth-of-type(1) {
      font-size: 6vw;
    }

    .video_title p:nth-of-type(2) {
      font-size: 3vw;
      width: unset;
    }

    :is(.Creator, .User) p:nth-of-type(1) {
      font-size: 6vw;
    }

    :is(.Creator, .User) p:nth-of-type(2),
    :is(.Creator, .User) p:nth-of-type(3) {
      font-size: 3.4vw;
    }

    :is(.Creator, .User) img {
      width: 34vw;
    }

    .switch img {
      padding: 20px 0px;
      width: 12vw;
    }

    .phone-title-bg-image {
      width: 250px;
      margin: auto;
    }

    .title-bg-image {
      font-size: 6vw !important;
      width: 200px !important;
    }
  }

  // 24/6/22
  .phone-title-bg-image {
    background: url('/assets/image/about-us/desktop/about-us_phone_title_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    // width: 350px;
    // height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .title-bg-image {
    background: url('/assets/image/about-us/desktop/about-us_title_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5vw;
  }

  .tt {
    font-size: 3vw;
  }

  hr.phone-title-line {
    border: 2px solid var(--v-secondary-base) !important;
    border-radius: 5px;
    background: var(--v-secondary-base);
    position: absolute;
    top: 27px;
    width: 150px;
    z-index: 0;
  }

  .bottom-left hr.phone-title-line {
    right: -100px;
  }

  .bottom-right hr.phone-title-line {
    left: -100px;
  }

  @media screen and (max-width: 1280px) {
    hr.phone-title-line {
      width: 100px;
    }
    .bottom-left hr.phone-title-line {
      right: -100px;
    }

    .bottom-right hr.phone-title-line {
      left: -75px;
    }
  }
  @media screen and (min-width: 1900px) {
    .bottom-left {
      left: -10%;
      width: 400px;
    }
    .bottom-right {
      right: -15%;
      width: 400px;
    }
    hr.phone-title-line {
      width: 145px;
      top: 30px;
    }
    .bottom-left hr.phone-title-line {
      right: -100px;
    }

    .bottom-right hr.phone-title-line {
      left: -75px;
    }
  }
  .v-application p {
    margin-bottom: unset !important;
  }
</style>
